<template>
  <v-container
    class="container-full-size"
  >
    <v-card
      color="basil"
      style="margin-top: 0;"
      flat
    >
      <v-data-table
        :headers="paymentHeaders"
        :items="payments"
        :no-data-text="str['payments_empty']"
        :no-results-text="str['payments_empty']"
        class="elevation-1"
        @click:row="openPayment"
        :loading="isLoading"
        :loading-text="str['processing']"
      >
        <template v-slot:item.type="{ item }">
          {{ getPaymentType(item) }}
        </template>
        <template v-slot:item.date="{ item }">
          {{ convertUtcDate(item.date) }}
        </template>
        <template v-slot:item.contents="{ item }">
          <span
            v-for="(cnt, cntIndex) in item.contents"
            :key="cnt"
          >
            {{ cntIndex > 0 ? ' | ' : '' }}{{ contents[cnt] ? contents[cnt] : cnt }}
          </span>
        </template>
        <template v-slot:item.value="{ item }">
          {{ item.value ? (item.value + getCurrency(item)) : '-' }}
        </template>
        <template v-slot:item.status="{ item }">
          <span v-bind:class="getPaymentStatus(item).type + '--text'">
            {{ getPaymentStatus(item).label }}
          </span>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    name: 'client-payments',
    props: ['parent', 'client'],
    data() {
      const paymentType = (Utils.getPaymentTypes() || []).find(p => p.value === window.gatewayType) || null
      const headers = [
        { text: window.strings['type'], value: 'type', align: 'center', width: 100 },
        { text: window.strings['date'], value: 'date', align: 'center', width: 140 },
      ]
      if (paymentType && paymentType.payments_table_headers) {
        paymentType.payments_table_headers.forEach(h => {
          headers.push({ text: window.strings[h.text], value: h.value, align: 'center', width: 150 })
        })
      }
      const list = Utils.getPaymentsPacksCategories()
      const hasSaleContentsCategory = 99
      const hasSaleContents = list && list.length ? list.some(item => item.value === hasSaleContentsCategory) : false
      if (hasSaleContents) {
        headers.push({ text: window.strings['contents'], value: 'contents', align: 'center', width: 120 })
      }
      headers.push(
        { text: window.strings['value'], value: 'value', align: 'center', width: 80 },
        { text: window.strings['status'], value: 'status', align: 'center', width: 100 },
      )
      return {
        str: window.strings,
        convertUtcDate: Utils.convertUtcDate,
        user: Utils.getUser(),
        paymentsViewPermission: Utils.hasPermission('payments_view'),
        getCurrency: Utils.getCurrency,
        hasSaleContents: hasSaleContents,
        isLoading: false,
        payments: [],
        paymentHeaders: headers,
        contentsMinType: 99,
        contents: {},
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      if (this.paymentsViewPermission) {
        this.getPayments()
      }
      if (this.hasSaleContents) {
        this.getContents()
      }
    },
    methods: {
      refresh: function () {
        this.getPayments()
      },
      getPayments: function () {
        const self = this
        this.isLoading = true
        Api.getPayments(this.client.dbId, function (response) {
          self.isLoading = false
          if (response.success) {
            if (self.hasSaleContents) {
              response.data.forEach(d => {
                d.contents = d.contents ? JSON.parse(d.contents) : []
              })
            }
            self.payments = response.data
            if (window.paymentToOpen) {
              for (let i = 0; i < self.payments.length; i++) {
                if (self.payments[i].id === window.paymentToOpen) {
                  self.openPayment(self.payments[i])
                  break
                }
              }
              window.paymentToOpen = null
            }
          }
        })
      },
      getContents: function () {
        Api.getContents({
          minType: this.contentsMinType,
          fields: ['id', 'name'],
          useCache: true,
        }, (response) => {
          if (response.success && response.data) {
            const contents = {}
            response.data.forEach(item => {
              contents[item.id] = item.name
            })
            this.contents = contents
          }
        })
      },
      getPaymentType: function (payment) {
        const types = Utils.getPaymentTypes()
        const type = types.find(function (t) {
          return t.value === payment.type
        })
        if (type) {
          return type.label
        } else {
          return window.strings['n_a']
        }
      },
      getPaymentStatus: function (payment) {
        let message = {
          type: 'default',
          label: window.strings['n_a'],
        }
        const statusItem = Utils.getPaymentStatus().find(function (item) {
          return item.value === payment.status
        })
        if (statusItem) {
          message = statusItem
        }
        return message
      },
      openPayment: function (payment) {
        const self = this
        this.$isLoading(true)
        Api.getPayment({
          db_id: this.client.dbId,
          id: payment.id,
        }, function (response) {
          self.$isLoading(false)
          const paymentData = response.data[0]
          if (response.success && paymentData) {
            paymentData.db_id = self.client.dbId
            Utils.setStorage('payment', paymentData)
            self.$router.navigate.push({ path: '/home/payment' })
          } else {
            self.$alert(
              response.message ? response.message : window.strings['payment_not_found'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
    },
  }
</script>
